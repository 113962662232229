<template>
	<errorContainer :error="error" :warning="warning">
		<b-col md="12">
			<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
				{{ FormMSG(1, 'Film Commission List') }}
			</h1>
		</b-col>
		<transition name="slide">
			<b-card no-body>
				<b-card-body>
					<b-row class="mb-3">
						<b-col cols="9" class="my-1">
							<b-form-group
								:label="FormMSG(2, 'Filter')"
								label-cols="2"
								label-align-sm="left"
								label-size="sm"
								label-for="filterInput"
								class="mb-0"
								v-if="$screen.width >= 992"
							>
								<b-input-group size="sm">
									<b-form-input
										v-model="filter"
										type="search"
										id="filterInput"
										:placeholder="FormMSG(3, 'Type to search...')"
										autocomplete="off"
									></b-form-input>
									<b-input-group-append>
										<b-button :disabled="!filter" @click="filter = ''">
											<i class="fa fa-times" aria-hidden="true"></i>
										</b-button>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col cols="3">
							<div class="float-right">
								<b-button :block="!$screen.md" @click="newCommission" variant="success" size="md" class="btn btn-primary btn-md float-right">
									{{ FormMSG(4, 'New Commission') }}
								</b-button>
							</div>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="12" xl="12">
							<b-table
								responsive="sm"
								ref="filmCommissionTable"
								:selected-variant="selectedColor"
								:hover="hover"
								:select-mode="selectMode"
								:items="contacts"
								:fields="fields"
								:head-variant="hv"
								:empty-text="FormMSG(10, 'No film commission found')"
								:current-page="currentPage"
								:filter="filter"
								:per-page="perPage"
								tbody-tr-class="p-0"
								selectable
								bordered
								small
								show-empty
							>
								<template v-slot:cell(edit)="data">
									<div class="d-flex justify-content-center">
										<b-button variant="none" size="sm" @click="editFilmCommission(data.item.id)" style="line-height: 16px">
											<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
										</b-button>
									</div>
								</template>
								<template v-slot:cell(delete)="data">
									<div class="d-flex justify-content-center">
										<b-button variant="none" size="sm" style="line-height: 16px" @click="deleteFilmCommission(data.item.id)">
											<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
										</b-button>
									</div>
								</template>
							</b-table>
						</b-col>
					</b-row>
				</b-card-body>
			</b-card>
		</transition>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { addUpdateFilmCommission, getFilmCommissions, delFilmCommission } from '@/cruds/film-commission.crud';
import { isNil } from '@/shared/utils';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'FilmCommissionList',
	mixins: [languageMessages, globalMixin],
	computed: {
		baseFields() {
			return [
				{
					key: 'name',
					label: this.FormMSG(5, 'Name'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'description',
					label: this.FormMSG(6, 'Description'),
					sortable: true
				},
				{
					key: 'webSite',
					label: this.FormMSG(7, 'Web Site'),
					sortable: true,
					formatter: (v) => v
				},
				{
					key: 'edit',
					label: this.FormMSG(8, 'Edit'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'delete',
					label: this.FormMSG(9, 'Remove'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		fields() {
			return this.baseFields;
		}
	},
	data() {
		return {
			filter: '',
			error: {},
			warning: '',
			hover: true,
			currentPage: 1,
			perPage: 0,
			selectMode: 'single',
			selectedColor: 'primary',
			hv: 'dark',
			contacts: []
		};
	},
	async created() {
		await this.reloadData();
	},
	methods: {
		async newCommission() {
			await addUpdateFilmCommission().then((record) => {
				if (record) {
					const path = `/film-commission/n/${record.id}`;
					this.$router.push({ path });
				}
			});
		},
		editFilmCommission(id) {
			const path = `/film-commission/u/${id}`;
			this.$router.push({ path });
		},
		deleteFilmCommission(id) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(11, 'Are you sure to remove this commission ?'), {
					title: this.FormMSG(12, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(13, 'YES'),
					cancelTitle: this.FormMSG(14, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false,
					noCloseOnBackdrop: true
				})
				.then(async (v) => {
					if (v) {
						await delFilmCommission(id).then(async (result) => {
							if (result) {
								await this.reloadData();
							}
						});
					}
				})
				.catch((err) => console.log(err));
		},
		async reloadData() {
			this.contacts = await getFilmCommissions();
		}
	},
	watch: {
		'$route.query': {
			async handler(n) {
				const { s, id } = n;

				if (!isNil(s)) {
					if (s === '0') {
						this.createToastForMobile(this.FormMSG(15, 'Success'), this.FormMSG(16, 'Film commission added successfully'), '');
					} else if (s === '1') {
						// delete
						await delFilmCommission(id).then((result) => {
							if (result) {
								this.createToastForMobile(this.FormMSG(17, 'Error'), this.FormMSG(18, 'Your data was not saved correctly'), '', 'danger');
							}
						});
					}
					this.$router.push('/film-commission');

					await this.reloadData();
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
